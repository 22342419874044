<template>
  <v-dialog :value="value" width="400" @input="test">
    <v-card dark color="#1C355E">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
          <v-list-item-title class="headline">{{ subtitle }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-progress-linear
        :active="get_dialog_load"
        :indeterminate="get_dialog_load"
        absolute
        color="blue"
      ></v-progress-linear>
      <v-divider></v-divider>
      <v-list rounded light dense>
        <v-list-item @click="$getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getReceivedPDF', BHA: '/getPDF' },
                'Receive_No',
                'ODP_BHA_Receive_Report',
                'ODP_BHA_Receive_Report_Print',
                'Receive',
              ]
            )">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Receive</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getOrderPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Order_Confirmation_V1',
                'ODP_BHA_Order_Confirmation_Print',
                'Order_Confirmation',
              ]
            )">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Order Confirmation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!blacklist.includes(getEmail)" @click="$getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getPreinvPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Pre_Invoice_V2',
                'ODP_BHA_Pre_Invoice_V2_Print',
                'Pre_Invoice',
                'ODP_BHA_Pre_Invoice_V2_Repair_Print',
                'ODP_BHA_Pre_Invoice_V2_Machining_Print',
              ]
            )">
          <v-list-item-content> 
            <v-list-item-title class="text-button text-left">Pre Invoice</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="bhaInspURL" v-if="bha">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Inspection Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getCOC_MC" v-if="!bha">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">COC Machining</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getCOC_HB" v-if="!bha">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">COC Hardbanding</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getInspectionTypePDF" v-if="!bha">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Inspection Report PDF</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getInspectionTypeEXCEL" v-if="!bha">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Inspection Report EXCEL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
<!--      <div :class="padding_class">
        <v-btn
          depressed
          color="white"
          block
          @click="
            $getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getReceivedPDF', BHA: '/getPDF' },
                'Receive_No',
                'ODP_BHA_Receive_Report',
                'ODP_BHA_Receive_Report_Print',
                'Receive',
              ]
            )
          "
        >
          Receive
          <v-spacer />
        </v-btn>
        <v-btn
          depressed
          color="white"
          block
          @click="
            $getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getOrderPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Order_Confirmation_V1',
                'ODP_BHA_Order_Confirmation_Print',
                'Order_Confirmation',
              ]
            )
          "
        >
          Order Confirmation
          <v-spacer />
        </v-btn>
        <v-btn
          depressed
          color="white"
          block
          @click="
            $getPDFglobal(
              undefined,itemWithProperties,[
                { Tubular: '/getPreinvPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Pre_Invoice_V2',
                'ODP_BHA_Pre_Invoice_V2_Print',
                'Pre_Invoice',
                'ODP_BHA_Pre_Invoice_V2_Repair_Print',
                'ODP_BHA_Pre_Invoice_V2_Machining_Print',
              ]
            )
          "
        >
          Pre Invoice
          <v-spacer />
        </v-btn>
        <v-btn
          v-if="bha"
          :loading="loaders['Dimensional_Report_load']"
          :to="bhaInspURL"
          depressed
          color="white"
          block
        >
          Inspection Reports
          <v-spacer />
        </v-btn>
        <v-btn v-else depressed color="white" block @click="getCOC_MC">
          COC Machining
          <v-spacer />
        </v-btn>
        <v-btn v-if="!bha" depressed color="white" block @click="getCOC_HB">
          COC Hardbanding
          <v-spacer />
        </v-btn>
        <v-btn
          v-if="!bha"
          depressed
          color="white"
          block
          @click="getInspectionTypePDF"
        >
          Inspection Report PDF
          <v-spacer />
        </v-btn>
        <v-btn
          v-if="!bha"
          depressed
          color="white"
          block
          @click="getInspectionTypeEXCEL"
        >
          Inspection Report EXCEL
          <v-spacer />
        </v-btn>
      </div>-->
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'bha_pdf_menu',
  props: [
    'currentOrdernr',
    'currentReceiveNo',
    'currentCOCHB', // Tubular
    'currentCOCMC', // Tubular
    'eq', // Tubular
    'value',
    'bha',
    'subtitle',
    'itemWithProperties',
    'bhaInspURL'
  ],
  data () {
    return {
      blacklist: ["ttys@odfjelltechnology.com", "lros@odfjelltechnology.com", "kjth@odfjelltechnology.com", "fsaa@odfjelltechnology.com"],
      loaders: {
        Receive_load: false,
        Order_Confirmation_load: false,
        Pre_Invoice_load: false,
        Dimensional_Report_load: false,
        Elevator_Links_load: false,
        Miscellaneous_load: false,
        Multi_Detail_load: false
      },
      preinvpdfload: false,
      recpdfload: false,
      orderpdfload: false,
      inspreploadPDF: false,
      inspreploadEXCEL: false,
      padding_class: 'pl-2',
      title: 'Order Number',
      dialogload: false,
      colorClass: 'light-blue lighten-4'
    }
  },
  computed: {
    ...mapGetters(['get_dialog_load', 'getEmail'])
  },
  methods: {
    ...mapActions(['set_error_state']),
    test () {
      this.dialogHeaderUpdate(false)
      this.$emit('input')
    },
    dialogHeaderUpdate (error, description) {
      if (error) {
        this.title = 'Error'
        this.subtitle =
          description.split('_')[0] +
          ' ' +
          description.split('_')[1] +
          ' PDF does not exist'
        this.colorClass = 'error'
      } else {
        this.title = 'Order Number'
        this.colorClass = 'light-blue lighten-4'
        this.subtitle = this.currentOrdernr
      }
    },
    /// / TUBULAR PDF Functions
    getInspectionTypePDF () {
      this.inspreploadPDF = true
      if (this.eq === 'Drill Pipe' || this.eq === 'Landing String') {
        // this.getdpPDF();
        this.$getPDFglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/getdpPDF' }, 'Order_No']
        )
      } else if (this.eq === 'Heavy Weight') {
        // this.gethwPDF();
        this.$getPDFglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/gethwPDF' }, 'Order_No']
        )
      } else if (this.eq === 'Drill Collar') {
        // this.getdcPDF();
        this.$getPDFglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/getdcPDF' }, 'Order_No']
        )
      } else {
        // this.getoctgPDF();
        this.$getPDFglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/getoctgPDF' }, 'Order_No']
        )
      }
    },
    getInspectionTypeEXCEL () {
      console.log(this.eq)
      this.inspreploadEXCEL = true
      if (this.eq === 'Drill Pipe' || this.eq === 'Landing String') {
        // this.getExceldp();
        this.$getEXCELglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/getdpExcel' }, 'Order_No']
        )
      } else if (this.eq === 'Heavy Weight') {
        // this.getExcelhw();
        this.$getEXCELglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/gethwExcel' }, 'Order_No']
        )
      } else if (this.eq === 'Drill Collar') {
        // this.getExceldc();
        this.$getEXCELglobal(undefined, this.itemWithProperties,
          [{ Tubular: '/getdcExcel' }, 'Order_No']
        )
      }
    },
    // getOrderPDF() {
    //   this.orderpdfload = true;
    //   axios
    //     .get("/getOrderPDF", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.orderpdfload = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    getCOC_HB () {
      if (this.currentCOCHB !== '') {
        window.open(this.currentCOCHB, '_blank')
      } else {
        this.set_error_state(true)
      }
    },
    getCOC_MC () {
      if (this.currentCOCMC !== '') {
        window.open(this.currentCOCMC, '_blank')
      } else {
        this.set_error_state(true)
      }
    }
    // getdpPDF() {
    //   axios
    //     .get("/getdpPDF", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadPDF = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // getoctgPDF() {
    //   console.log(this.currentOrdernr);
    //   axios
    //     .get("/getoctgPDF", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadPDF = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // gethwPDF() {
    //   axios
    //     .get("/gethwPDF", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadPDF = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // getdcPDF() {
    //   axios
    //     .get("/getdcPDF", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadPDF = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // getExceldp() {
    //   axios
    //     .get("/getdpExcel", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadEXCEL = false;
    //       window.open(response.data.excellink, "_blank");
    //     });
    // },
    // getExcelhw() {
    //   axios
    //     .get("/gethwExcel", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadEXCEL = false;
    //       window.open(response.data.excellink, "_blank");
    //     });
    // },
    // getExceldc() {
    //   axios
    //     .get("/getdcExcel", {
    //       params: { orderno: this.currentOrdernr },
    //     })
    //     .then((response) => {
    //       this.inspreploadEXCEL = false;
    //       window.open(response.data.excellink, "_blank");
    //     });
    // },
  }
}
</script>

<style scoped>
</style>
